import styled from '@emotion/styled'
import Link from 'next/link'

const UserLink = styled.a`
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }
`

const ListRowAuthor = ({ User, name }) => {
  if (User?.username) {
    return (
      <div>
        {'by '}
        <Link href={`/user/${User.username.toLowerCase()}`}>
          <UserLink href={`/user/${User.username.toLowerCase()}`}>
            {User.username}
          </UserLink>
        </Link>
      </div>
    )
  } else {
    return <div>by {name}</div>
  }
}

export default ListRowAuthor
