import Spinner from 'react-loader-spinner'
import { useTheme } from '@emotion/react'

const Loading = ({ height, width }) => {
  const theme = useTheme()

  return (
    <div
      style={{ height: `${height}px`, margin: '0 auto', width: `${width}px` }}
    >
      <Spinner
        type="ThreeDots"
        color={theme.colors.text}
        height={height}
        width={width}
      />
    </div>
  )
}

Loading.defaultProps = {
  height: 38,
  width: 38,
}

export default Loading
