import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Loading from './Loading'

export const common = (props) => css`
  border-style: none;
  border-radius: 0.125rem;
  border-width: 0;
  font-weight: 600;
  letter-spacing: -0.01rem;
  outline: 0;
  transition: box-shadow 0.25s ease 0s;

  &:disabled {
    cursor: not-allowed;

    &:hover:enabled,
    &:focus {
      box-shadow: none;
    }
  }

  &:hover:enabled,
  &:focus {
    cursor: pointer;
  }
`

export const smallRules = css`
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
`

export const largeRules = css`
  font-size: 0.875rem;
  min-width: 6rem;
  padding: 1rem 2rem;

  @media screen and (min-width: 30em) {
    font-size: 1rem;
  }
`

const PrimaryButton = styled.button`
  ${common}
  ${({ small }) => (small ? smallRules : largeRules)}
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.background};

  &:hover:enabled,
  &:focus {
    box-shadow: ${({ theme }) => theme.colors.blueBoxShadow} 0px 0px 0px 4px;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }
`

const SecondaryButton = styled.button`
  ${common}
  ${({ small }) => (small ? smallRules : largeRules)}
  background-color: ${({ theme }) => theme.colors.gray};
  color: ${({ theme }) => theme.colors.background};

  &:hover:enabled,
  &:focus {
    box-shadow: ${({ theme }) => theme.colors.grayBoxShadow} 0px 0px 0px 4px;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }
`

const WarningButton = styled.button`
  ${common}
  ${({ small }) => (small ? smallRules : largeRules)}
  background-color: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.background};

  &:hover:enabled,
  &:focus {
    box-shadow: ${({ theme }) => theme.colors.grayBoxShadow} 0px 0px 0px 4px;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }
`

const WhiteButton = styled.button`
  ${common}
  ${({ small }) => (small ? smallRules : largeRules)}
  background-color: transparent;
  color: ${({ theme }) => theme.colors.red};

  &:hover:enabled,
  &:focus {
    box-shadow: ${({ theme }) => theme.colors.grayBoxShadow} 0px 0px 0px 4px;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.lightGray};
  }
`

function Btn(props) {
  if (props.secondary) {
    return <SecondaryButton {...props} />
  } else if (props.warning) {
    return <WarningButton {...props} />
  } else if (props.white) {
    return <WhiteButton {...props} />
  } else {
    return <PrimaryButton {...props} />
  }
}

const Button = ({
  children,
  className,
  disabled,
  loading,
  onClick,
  secondary,
  small,
  warning,
  white,
}) => (
  <Btn
    className={className}
    disabled={disabled || loading}
    onClick={onClick}
    secondary={secondary}
    small={small}
    warning={warning}
    white={white}
  >
    {loading ? <Loading height={16} width={24} /> : children}
  </Btn>
)

export default Button
