import { isDate } from 'lodash'

export const elide = (string, length = 140) => {
  if (isEmpty(string)) {
    return '...'
  }

  if (string.length < length) {
    return string.trim()
  }

  return `${string.substring(0, length)}...`
}

function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
}

export const isEmpty = (string) => {
  return !string || string.length === 0
}

export const pluralize = (text, count) => {
  return count > 1 || count === 0 ? `${text}s` : text
}

const emailRegexp =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
export const isEmail = (email) => emailRegexp.test(email)

export const isEmptyOrNull = (string) => {
  return !string || !string.trim()
}

export function goodURL(url) {
  if (url.slice(0, 7) !== 'http://' && url.slice(0, 8) !== 'https://') {
    return `http://${url}`
  } else {
    return url
  }
}

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace)
}

export const toDate = (input) => {
  let date = input
  if (!isDate(input)) {
    date = new Date(input.toString().replace(' ', 'T'))
  }
  const month = date.toLocaleString('default', { month: 'long' })
  return `${month} ${date.getDate()}, ${date.getFullYear()}`
}

export const truncate = (text, length = 150) => {
  if (text.length > length) {
    return text.substring(0, length - 3) + '...'
  } else {
    return text
  }
}

export function wrapLinks(text) {
  var urlPattern =
    /(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)(?:\.(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)*(?:\.(?:[a-z\x{00a1}\-\x{ffff}]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?/gi

  return text.replace(urlPattern, function (url) {
    var protocolPattern = /^(?:(?:https?|ftp):\/\/)/i
    var href = protocolPattern.test(url) ? url : 'https://' + url
    return `<a href="${href}" rel="noopener noreferrer" target="_blank">${url}</a>`
  })
}
