import Link from 'next/link'
import useSWR from 'swr'
import Button from '../components/Button'
import Collection from '../components/Collection'
import FooterCopy from '../components/FooterCopy'
import { H2, P, TextLink } from '../components/typography'
import { get } from '../lib/fetch'
import { getCollections } from '../sequelize/queries'

const IndexPage = ({ collections }) => {
  const { data } = useSWR('/api/lists?perPage=8', get)
  const recentLists = data && data.results

  return (
    <>
      <P className="mb4">
        Buy Music Club is a place for creating and browsing lists of independent
        music purchasable on Bandcamp.
      </P>

      <div className="mb5">
        <Link href="/new">
          <a>
            <Button>Create your own list</Button>
          </a>
        </Link>
      </div>

      <H2 className="mb1">Our favorite lists.</H2>

      <P className="mb5">
        We&apos;ve curated a selection of our favorite lists that you&apos;ve
        submitted.
        <br />
        You can browse the latest <TextLink href="/recent">here</TextLink>.
      </P>

      {collections &&
        collections.length > 0 &&
        collections.map((collection, i) => (
          <Collection
            className="mb5"
            description={collection.description}
            key={collection.title + i}
            Lists={collection.Lists}
            title={collection.title}
          />
        ))}

      {recentLists && (
        <Collection
          className="mb5"
          Lists={recentLists}
          title="Recent lists"
          viewMoreUrl="/recent"
        />
      )}

      <FooterCopy />
    </>
  )
}

export async function getStaticProps() {
  if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true') {
    return {
      props: {
        collections: {},
      },
    }
  }

  const collections = await getCollections()
  return {
    props: {
      collections,
    },
    revalidate: 60,
  }
}

export default IndexPage
