import styled from '@emotion/styled'
import ListRow from './ListRow'
import { H3, TextLink } from './typography'

const Header = styled.div`
  p:first-of-type {
    margin-top: 0;
  }
`

const ListsContainer = styled.ul`
  list-style-type: none;
`

const StyledListRow = styled(ListRow)`
  margin-bottom: ${({ theme }) => theme.space[3]};
`

const Collection = ({ className, description, Lists, title, viewMoreUrl }) => (
  <div
    className={className}
    data-cy={viewMoreUrl === '/recent' ? 'RecentLists' : 'Collection'}
  >
    <Header className="mb4">
      <H3 className="ma0 mb1">{title}</H3>

      {description && (
        <div
          className="lh-copy mb4 mt0 w-100"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
    </Header>

    <ListsContainer className="pa0">
      {Lists.map((list) => (
        <StyledListRow
          displayAuthor
          displayDate
          key={list.slug}
          hero_image={list.hero_image}
          published_at={list.published_at}
          ListItems={list.ListItems}
          name={list.name}
          slug={list.slug}
          title={list.title}
          User={list.User}
        />
      ))}
    </ListsContainer>

    {viewMoreUrl && <TextLink href={viewMoreUrl}>{`More ${title} >`}</TextLink>}
  </div>
)

export default Collection
