import styled from '@emotion/styled'
import Link from 'next/link'
import { toDate } from '../../lib/strings'
import ListRowAuthor from './ListRowAuthor'
import ListRowImage from './ListRowImage'

const Container = styled.li`
  display: flex;
`

const StyledListRowImage = styled(ListRowImage)`
  margin-right: ${({ theme }) => theme.space[2]};

  ${({ theme }) => theme.media.lUp} {
    margin-right: ${({ theme }) => theme.space[3]};
  }
`

const Title = styled.a`
  display: inline-block;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 600;
  text-decoration: none;
`

const ListRow = ({
  className,
  displayAuthor,
  displayDate,
  hero_image: heroImage,
  published_at: publishedAt,
  ListItems,
  name,
  slug,
  title,
  token,
  User,
}) => {
  let listUrl
  if (publishedAt === null) {
    listUrl = `/list/${slug}/edit/${token}`
  } else {
    listUrl = `/list/${slug}`
  }

  return (
    <Container className={className} data-cy="ListRow" key={slug}>
      <StyledListRowImage
        heroImage={heroImage}
        ListItems={ListItems}
        listUrl={listUrl}
      />

      <div className="f6 f5-ns flex flex-column justify-center lh-copy">
        <div>
          <Link href={listUrl}>
            <Title href={listUrl}>{title}</Title>
          </Link>
          {displayAuthor && <ListRowAuthor User={User} name={name} />}
          {displayDate && publishedAt && (
            <div className="f7 mt2">{toDate(publishedAt)}</div>
          )}
        </div>
      </div>
    </Container>
  )
}

ListRow.defaultProps = {
  displayAuthor: false,
  displayDate: false,
}

export default ListRow
