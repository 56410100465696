import styled from '@emotion/styled'
import Link from 'next/link'
import { useState } from 'react'
import { handleBrokenBandcampUrl } from '../../lib/viewHelpers'

const ImgContainer = styled.a`
  background: ${({ theme }) => theme.colors.background};
  border-radius: 0.125rem;
  flex-shrink: 0;
  height: 4rem;
  width: 4rem;

  ${({ theme }) => theme.media.sUp} {
    height: 7rem;
    width: 7rem;
  }

  ${({ theme }) => theme.media.lUp} {
    height: 8rem;
    width: 8rem;
  }
`

const getListImage = (heroImage, ListItems, url) => {
  const transform = '-/scale_crop/256x256/smart_faces_objects/'

  if (heroImage) {
    return heroImage + transform
  } else if (ListItems && ListItems.length > 0) {
    return ListItems[0].image
  } else {
    return null
  }
}

const ListRowImage = ({ className, heroImage, ListItems, listUrl }) => {
  const listImage = getListImage(heroImage, ListItems)

  const [imgSrc, setImgSrc] = useState(listImage)
  const [loading, setLoading] = useState(false)

  const handleImageError = () => {
    // ignore unless image is from Bandcamp CDN
    if (imgSrc.includes('bcbits')) {
      // This is currently broken during SSR:
      // https://github.com/facebook/react/issues/15446
      handleBrokenBandcampUrl({
        externalId: ListItems[0].externalId,
        url: imgSrc,
      })
    }
  }

  return (
    <Link href={listUrl}>
      <ImgContainer className={className} href={listUrl}>
        {!loading && imgSrc && (
          <img alt="list image" onError={handleImageError} src={imgSrc} />
        )}
      </ImgContainer>
    </Link>
  )
}

export default ListRowImage
