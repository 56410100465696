import { P, TextLink } from './typography'

const FooterCopy = () => (
  <>
    <P className="mb1">
      Read about Buy Music Club, who is working on it and how to contact us{' '}
      <TextLink href="/about">here</TextLink>.
    </P>
    <P>
      If you like the site and would like to support the project, please
      consider making a donation <TextLink href="/donate">here</TextLink>.
    </P>
  </>
)

export default FooterCopy
